import React, { useState } from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

import Layout from '../organisms/layout'
import HubSpotFormTag from '../organisms/hubSpotFormTag'
import Theme, { Mq } from '../themes/default'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'
import VideoPlayer from '../atoms/video-player'
import ContactUsForm from '../molecules/contact-us-form'

const ContactUsFormWrapper = styled.div`
  padding: 3rem 5%;
  height: 100%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3% 6rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 3% 12% 0;
  }
`

const InclusivityRoadmap = () => {
  const [didSubmit, setDidSubmit] = useState(false)

  const onSubmission = () => {
    setDidSubmit(true)
    window.scrollTo(0, 0)
  }

  const subpageHeroText = didSubmit
    ? 'No matter where you are in your inclusivity journey, we are here to help. Watch our CEO, Michele Ruiz, and our Chief Learning Officer & Organizational Strategist, Crystal Miller, Ph.D., as they introduce BiasSync\'s Inclusivity Roadmap.'
    : 'Please complete the form below for instant viewing access to BiasSync\'s Inclusivity Roadmap Webinar.'
  return (
    <Layout>
      <SEO
        description="Inclusivity Roadmap Page"
        title="Inclusivity Roadmap Educational Webinar"
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow, nosnippet'
          }
        ]}
      />
      <HubSpotFormTag formId={process.env.GATSBY_HUBSPOT_INCLUSIVITY_ROADMAP_FORM_ID} />
      <SubpageHero title="Inclusivity Roadmap Webinar" text={subpageHeroText} />
      {didSubmit ? (
        <Fade bottom cascade distance={Theme.fadeDistance}>
          <VideoPlayer
            closedCaptionsSrc="https://d36inhqiypzgeo.cloudfront.net/inclusivity-1/closed_captions/en.vtt"
            src="https://d36inhqiypzgeo.cloudfront.net/inclusivity-1/2021_06_14-BiasSync-InclusivityRoadmapEducation-Webinar-V1_720p.mp4"
            videoTitle="Inclusivity Roadmap Webinar"
          />
        </Fade>
      ) : (
        <Fade bottom cascade distance={Theme.fadeDistance}>
          <ContactUsFormWrapper>
            <ContactUsForm
              contactType="inclusivity_roadmap"
              onSubmission={onSubmission}
              ctaButtonText="Submit"
            />
          </ContactUsFormWrapper>
        </Fade>
      )}
    </Layout>
  )
}

export default InclusivityRoadmap
